<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div>

		<!-- Sign Up Image And Headings -->
		<div class="sign-up-header" style="background-image: url('images/image2.jpg')">
			<div class="content">
				<h1 class="mb-5">Create An Account</h1>
				<p class="text-lg">Join Stargate Credit Union today. It's quick and easy to start banking with us.</p>
			</div>
		</div>
		<!-- / Sign Up Image And Headings -->
		
		<!-- Sign Up Form -->
		<a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{paddingTop: 0}">

			<p class="text-center my-25 font-semibold text-muted"></p>
			<a-form
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form"
				@submit="handleSubmit"
			>
				<a-form-item class="mb-10" label="First Name" :colon="false">
					<a-input
						v-decorator="[
						'first_name',
						{ rules: [{ required: true, message: 'Please input your first name!' }] },
						]"
						placeholder="John"
					>
					</a-input>
				</a-form-item>
        <a-form-item class="mb-10" label="Last Name" :colon="false">
          <a-input
              v-decorator="[
						'last_name',
						{ rules: [{ required: true, message: 'Please input your last name!' }] },
						]"
              placeholder="Doe"
          >
          </a-input>
        </a-form-item>
				<a-form-item class="mb-10" label="Email" :colon="false">
					<a-input
						v-decorator="[
						'email',
						{ rules: [{ required: true, message: 'Please input your email!' }] },
						]"
						placeholder="john@gmail.com"
					>
					</a-input>
				</a-form-item>
        <a-form-item class="mb-10" label="Address" :colon="false">
          <a-input
              v-decorator="[
						'address',
						{ rules: [{ required: true, message: 'Please input your full address!' }] },
						]"
              placeholder="No 1 union street"
          >
          </a-input>
        </a-form-item>
        <a-form-item class="mb-10" label="Zip Code" :colon="false">
          <a-input
              v-decorator="[
						'zip_code',
						{ rules: [{ required: true, message: 'Please input your address Zip Code!' }] },
						]"
              placeholder="45562"
          >
          </a-input>
        </a-form-item>
        <a-form-item class="mb-10" label="SSN (Optional)" :colon="false">
          <a-input

              v-decorator="[
						'ssn',
						{ rules: [{ required: false, message: 'Please input your SSN!' }] },
						]"
              placeholder="ERR446EE)"
          >
          </a-input
              >
        </a-form-item>
		<a-form-item class="mb-10" label="Account Type" :colon="false">
      <a-select
        v-decorator="[
          'account_type',
          { rules: [{ required: true, message: 'Select Account Type' }] },
        ]"
        placeholder="Select Account Type"
        @change="handleInputChange"
      >
        <a-select-option v-for="(option, index) in options" :key="index" :value="option.value">
          {{ option.label }}
        </a-select-option>
      </a-select>
    </a-form-item>
  <a-form-item class="mb-10" label="Upload Valid Govt Issued ID, Drivers Licence or International Passport" :colon="false">
      <a-upload
        v-decorator="[
          'file',
          { valuePropName: 'fileList', getValueFromEvent: this.normFile },
        ]"
        multiple
        :beforeUpload="() => false"
      >
        <a-button>
          <a-icon type="upload" /> Select File
        </a-button>
      </a-upload>
    </a-form-item>
				<a-form-item class="mb-10">
					<a-checkbox
						v-decorator="[
						'remember',
						{
							valuePropName: 'checked',
							initialValue: true,
							required: true
						},
						]"
					>
						I agree the <a href="#" class="font-bold text-dark">Terms and Conditions</a>
					</a-checkbox>
				</a-form-item>
				<a-form-item>
          <div v-if="!isLoading">
					<a-button type="primary" block html-type="submit" class="login-form-button">
						Create Account
					</a-button>
          </div>
          <div v-else style="text-align: center">
            <a-spin size="large" />
          </div>
				</a-form-item>
        <a-alert v-if="successful" class="mb-10" message="Account created successfully. Login details has been sent to your email. If for some reason, you don't see the email, please check spam or contact support. You may now login!" type="success" show-icon />
        <a-alert v-if="emailExist" class="mb-10" message="Account with this email already exists!" type="info" show-icon />
        <a-alert v-if="failed" class="mb-10" message="An error occurred! Please try again later." type="error" show-icon />

      </a-form>
			<p class="font-semibold text-muted text-center">Already have an account? <router-link to="/login" class="font-bold text-dark">Log In</router-link></p>
		</a-card>
		<!-- / Sign Up Form -->

	</div>
</template>

<script>
let axios = require("../axios")

	export default ({
		data() {
			return {
        successful: false,
        emailExist: false,
        failed: false,
        isLoading: false,
		options: [
        { label: 'Personal Savings Account', value: 'Personal Savings Account' },
        { label: 'Personal Checking Account', value: 'Personal Checking Account' },
        { label: 'Personal Investment Checking Account', value: 'Personal Investment Checking Account' },
      { label: 'Prestige Checking Account', value: 'Prestige Checking Account' },
      { label: 'Life Insurance Account', value: 'Life Insurance Account' },
      { label: 'Credit Card Management Account', value: 'Credit Card Management Account' },
      { label: 'Certificate of Deposit and Money Account', value: 'Certificate of Deposit and Money Account' }
      ],
	  option: null
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			normFile(e) {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    },
			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
            console.log('Received values of form: ', values) ;
            this.isLoading = true;
            let data = {
              "email": values.email,
              "fname": values.first_name,
              "lname": values.last_name,
              "address": values.address,
              "zip_code": values.zip_code,
              "ssn": values.ssn ? values.ssn : 'EFFEEDSA',
			        "account_type": values.account_type
            }
            this.submitForm(data)
            }
				});
			},
			handleInputChange(value) {
      // Handle input change if needed
    },
      async submitForm(data){
        this.emailExist = false
        this.successful = false
        this.failed = false
        try {
          const response = await axios.post("/create-account/", data)
          console.log(response)
          this.isLoading = false;
          if (response.data === "successful"){
            this.successful = true
            window.setTimeout(() => {
              this.$router.push("/")
            }, 30000)
          }else if(response.data === "Account Exists"){
            this.emailExist = true
          }else{
            this.failed = true
          }
        }catch (e) {
          console.log(e.response)
          this.failed = true
          this.isLoading = false;
        }
      }
		},
	})

</script>

<style lang="scss">
</style>